
import './LoginLyceum.css'
import axios from 'axios'
import imgLoginPage from '../../img/loginimg.png'
import imgLogoWhite from '../../img/young_branco.png'
import iconPerson from '../../icons/person.png'
import Img from '../../components/Img'
import Icon from '../../components/Icon'
import Text from '../../components/Text'
import iconPsw from '../../icons/psw.png'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const LoginLyceum = () => {

    const { login } = useAuth()
    const navigate = useNavigate()
    //VARIÁVEIS
    const [emailCadastro, setEmailCadastro] = useState(null)
    const [pswLyceum, setPswLyceum] = useState(null)
    const [newPsw, setNewPsw] = useState(null)
    const [newPswConfirm, setNewPswConfirm] = useState(null)
    const [newName, setNewName] = useState(null)
    const [buttonContinuar, setButtonContinuar] = useState(true)
    const [contentLogin, setContentLogin] = useState(false)
    const [contentCadastro, setContentCadastro] = useState(false)
    const [confirmacaoDeSenhas, setConfirmacaoDeSenhas] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [continueChangePsw, setContinueChangePsw] = useState(false)
    const [changePswToken, setChangePswToken] = useState(null)
    const [changePswTokenConfirm, setChangePswTokenConfirm] = useState(null)
    const [tokenChangePsw, setTokenChangePsw] = useState(null)

    const resetModal = () => {
        setShowModal(false)
        setContinueChangePsw(false)
        setChangePswToken(null)
        setTokenChangePsw(null)
    }

    const requestChangePsw = async () => {
        if(!changePswToken){
            alert('Você não informou uma nova senha, verifica por favor! <3')
            return
        }
        if(!tokenChangePsw){
            alert('Você não informou o token recebido no e-mail, verifica por favor! <3')
            return
        }
        try {
            console.log('DEV - Iniciando requisição de ALTERAÇÃO DE SENHA! ♥')
            const responseAlteraSenha = await axios.put(`${process.env.REACT_APP_REQ}resetpassword/`, {
                token: tokenChangePsw,
                new_password: changePswToken
            })
            if (responseAlteraSenha.data) {
                console.log('DEV - ♥ Deu certo, responseAlteraSenha.data da ALTERAÇÃO DE SENHA:', responseAlteraSenha.data)
                alert(responseAlteraSenha.data.msg)
                resetModal()
            } else {
                console.log('Falha ao fazer login!')
            }
          } catch (error) {
                console.log(error.msg)
          }
    }

    const requestTokenFunction = async () => {
        if(!emailCadastro){
            alert('Você não informou o e-mail, verifica por favor! <3')
            return
        }
        try {
            console.log('DEV - Iniciando requisição de SOLICITAÇÃO DE TOKEN PARA ALTERAÇÃO DE SENHA! ♥')
            const response = await axios.post(`${process.env.REACT_APP_REQ}requestoken/`, {
                email: emailCadastro
            })
            if (response.data) {
                console.log('DEV - ♥ Deu certo, response.data da SOLICITAÇÃO DE TOKEN PARA ALTERAÇÃO DE SENHA:', response.data)
                alert(response.data.msg)
                setContinueChangePsw(true)
            } else {
                console.log('Falha ao fazer login!')
            }
          } catch (error) {
                console.log(error.msg)
          }
    }

    function calculateButtonClass() {
        if (!emailCadastro || !pswLyceum) {
          return 'divLoginLyceumRightButtonDisabled'
        } else {
          return 'divLoginLyceumRightButton'
        }
    }

    function calculateButtonClassEmail() {
        if (!emailCadastro) {
          return 'divLoginLyceumRightButtonDisabled'
        } else {
          return 'divLoginLyceumRightButton'
        }
    }
    function calculateButtonClassCadastro() {
        if (!newPsw || !newPswConfirm || !newName) {
          return 'divLoginLyceumRightButtonDisabled'
        } else {
          return 'divLoginLyceumRightButton'
        }
    }

    const isEmailValid = (email) => {
        const regex = /\S+@\S+\.\S+/
        return regex.test(email)
    }
    const checkAcess = async () => {
        if (!emailCadastro) {
            alert('Você não informou o e-mail, verifica por favor! <3')
            return
        }
    
        if (!isEmailValid(emailCadastro)) {
            alert('Você não informou um e-mail com estrutura válida, verifica por favor! <3')
            return
        }
    
        try {
            console.log('DEV - Iniciando requisição de VERIFICAÇÃO DE ACESSO! ♥');
            const response = await axios.post(`${process.env.REACT_APP_REQ}checkuser/`, {
                email: emailCadastro
            })
    
            if (response.status === 200) {
                setButtonContinuar(false)
                setContentLogin(true)
                console.log('DEV - 200 ♥ Deu certo, response.data da VERIFICAÇÃO DE ACESSO:', response.data)
            } else {
                console.log('Falha ao fazer login!')
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                if (error.response.status === 502) {
                    alert('Erro de gateway (502): ' + error.message)
                } else if (error.response.status === 501) {
                    alert('Erro não implementado (501): ' + error.message)
                } else {
                    console.log('DEV - Erro na requisição 400 ou + ♥:', error)
                    setButtonContinuar(false)
                    setContentCadastro(true)
                    }
            }
        }
    }

    //
    const newUser = async () => {
        if(!emailCadastro){
            alert('Você não informou o e-mail, verifica por favor. <3')
            return
        }
        if(!newName){
            alert('Você não informou seu nome, verifica por favor. <3')
            return
        }
        if(!newPsw){
            alert('Você não informou sua senha à cadastrar, verifica por favor. <3')
            return
        }
        if(newPsw !== newPswConfirm){
            alert('As duas senhas não coincidem, favor verificar. <3')
            return
        }

        try {
            console.log('DEV - Iniciando requisição de CADASTRO! ♥')
            const responseCadastro = await axios.post(`${process.env.REACT_APP_REQ}user/`, {
                email: emailCadastro,
                name: newName,
                password: newPsw,
            })
            if (responseCadastro) {
                console.log('DEV - ♥ Deu certo, responseCadastro.data:', responseCadastro.data)
                alert(responseCadastro.data.msg)
                window.location.reload()
            } else {
                console.log('Falha ao cadastrar! V E R I F I C A R')
            }
          } catch (error) {
                console.log('error.response.data', error.response.data)
                console.log('error.response.data.msg', error.response.data.msg)
                alert(error.response.data.msg)
          }
    }

    
    const ConfirmEqualPsw =  (e) => {
        setNewPswConfirm(e.target.value)
        if(newPsw !== e.target.value){
            setConfirmacaoDeSenhas(true)
        }else{
            setConfirmacaoDeSenhas(false)
        }

    }

    // LOGIN
    const acessLyceum = async () => {
        if(!emailCadastro){
            alert('Você não informou o e-mail, verifica por favor. <3')
            return
        }
        if(!pswLyceum){
            alert('Você não informou a senha, pode verificar? Por favor. <3')
            return
        }
        try {
            console.log('DEV - Iniciando requisição de LOGIN! ♥')
            const response = await axios.post(`${process.env.REACT_APP_REQ}login/`, {
                email: emailCadastro,
                password: pswLyceum,
            })
            if (response.data && response.data.Bearer) {
                login(response.data.Bearer)
                console.log('DEV - ♥ Deu certo, response.data:', response.data)
                navigate('/search')
            } else {
                console.log('Falha ao fazer login! V E R I F I C A R')
            }
          } catch (error) {
                console.log('error.response', error.response.data.msg)
                alert(error.response.data.msg)
          }
    }

    

    return (
        <div className="divLoginLyceum">
            <div className='divLoginLyceumLeft'>
                <div></div>
                <div className='divLoginLyceumLeftItem'>
                    <Img className={'divLoginLyceumLeftImg'} imageUrl={imgLoginPage} alt={'Imagem página de Login'} />
                    <Img className={'divLoginLyceumLeftLogo'}  imageUrl={imgLogoWhite} alt={'Logo'} />
                </div>
                
                
            </div>
            <div className='divLoginLyceumRight'>
                <div className='divLoginLyceumRightTitle'>
                    <Icon className={'divLoginLyceumRightIconImg'} imageUrl={iconPerson} alt={'Ícone'}/>
                    <Text className={'divLoginLyceumRightText'} text={'Informe seu e-mail:'} />
                </div>
                <Input className={'divLoginLyceumRightInput'} type={'text'} placeholder={'Informe seu login e-mail'} value={emailCadastro} onChange={(e) => setEmailCadastro(e.target.value)} />
                {buttonContinuar && <Button onClick={checkAcess} text={'Continuar'} className={calculateButtonClassEmail()} />}
                {contentLogin &&
                    <div className='divLoginLyceumColumn'>
                        <div className='divLoginLyceumRightTitle'>
                            <Icon className={'divLoginLyceumRightIconImgLittleSize'} imageUrl={iconPsw} alt={'Ícone'}/>
                            <Text className={'divLoginLyceumRightText'} text={'Informe sua senha:'} />
                        </div>
                        <div className='divLoginLyceumColumn'>
                            <Input className={'divLoginLyceumRightInput'} type={'password'} placeholder={'Informe sua senha'} value={pswLyceum} onChange={(e) => setPswLyceum(e.target.value)}/>
                            <Button onClick={acessLyceum} className={calculateButtonClass()} text={'Acessar'} disabled={!emailCadastro || !pswLyceum} />
                        </div>
                    </div>
                }
                {contentCadastro &&
                    <div className='divLoginLyceumColumn'>
                        <div className='divLoginLyceumColumn'>
                            <div className='divLoginLyceumRightTitle'>
                                <Icon className={'divLoginLyceumRightIconImgLittleSize'} imageUrl={iconPerson} alt={'Ícone'}/>
                                <Text className={'divLoginLyceumRightText'} text={'Informe seu nome:'} />
                            </div>
                            <div className='divLoginLyceumColumn'>
                                <Input className={'divLoginLyceumRightInput'} type={'text'} placeholder={'Informe seu nome'} value={newName} onChange={(e) => setNewName(e.target.value)}/>
                            </div>
                        </div>
                        <div className='divLoginLyceumColumn'>
                            <div className='divLoginLyceumRightTitle'>
                                <Icon className={'divLoginLyceumRightIconImgLittleSize'} imageUrl={iconPsw} alt={'Ícone'}/>
                                <Text className={'divLoginLyceumRightText'} text={'Crie uma senha:'} />
                            </div>
                            <div className='divLoginLyceumColumn'>
                                <Input className={'divLoginLyceumRightInput'} type={'password'} placeholder={'Crie sua senha'} value={newPsw} onChange={(e) => setNewPsw(e.target.value)}/>
                            </div>
                        </div>
                        <div className='divLoginLyceumColumn'>
                            <div className='divLoginLyceumRightTitle'>
                                <Icon className={'divLoginLyceumRightIconImgLittleSize'} imageUrl={iconPsw} alt={'Ícone'}/>
                                <Text className={'divLoginLyceumRightText'} text={'Confirme sua senha:'} />
                            </div>
                            <div className='divLoginLyceumColumn'>
                                <Input className={'divLoginLyceumRightInput'} type={'password'} placeholder={'Informe sua senha novamente'} value={newPswConfirm} onChange={ConfirmEqualPsw}/>
                                {confirmacaoDeSenhas && <div><b><Text className={'divLoginLyceumTextAlert'} text={'ATENÇÃO! As senhas informadas não conferem :('} /></b></div>}
                            </div>
                        </div>
                        <Button onClick={newUser} className={calculateButtonClassCadastro()} text={'Cadastrar'}  />
                    </div>
                }
                <b><Button className={'divLoginLyceumRightButtonDisabled'} text={'Redefinir senha'} onClick={() => setShowModal(true)}/></b>
                <Modal show={showModal} onHide={resetModal} >
                    <Modal.Header closeButton>
                        <Modal.Title>RESET DE SENHA:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='divLoginLyceumModal'>
                            <Input className={'divLoginLyceumRightInputModal'} type={'text'} placeholder={'Informe seu login e-mail'} value={emailCadastro} onChange={(e) => setEmailCadastro(e.target.value)} />
                            <Button onClick={requestTokenFunction} className={'divLoginLyceumRightButtonModal'} text={'Solicitar token'}  />
                            {continueChangePsw && 
                                <div className='divLoginLyceumColumn'>
                                    <Input className={'divLoginLyceumRightInputModal'} type={'text'} placeholder={'Informe o token recebido'} value={tokenChangePsw} onChange={(e) => setTokenChangePsw(e.target.value)} />
                                    <Input className={'divLoginLyceumRightInputModal'} type={'password'} placeholder={'Informe sua nova senha'} value={changePswToken} onChange={(e) => setChangePswToken(e.target.value)} />
                                    <Button onClick={requestChangePsw} className={'divLoginLyceumRightButtonModal'} text={'Alterar senha'}  /> 
                                </div>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={'divLoginLyceumbuttonCloseModal'} text={'Fechar'} onClick={resetModal} />
                    </Modal.Footer>
                </Modal>
            </div>


            
        </div>
    )
}

export default LoginLyceum