
import './PerformancePage.css'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Navigate, useNavigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import IconMenu from '../../components/IconMenu'
import search from '../../icons/search.png'
import checkedImg from '../../img/checkedImg.png'
import userImg from '../../img/user.png'
import listImg  from '../../img/list.png'
import exit from '../../icons/exit.png'
import imgPerformance from '../../img/img123.png'
import blackHeart from '../../img/cora_preto.png'
import { Link } from 'react-router-dom'
import loadingImageFromPage from '../../img/loading_laranja.png'
import LoadingImageRotate from '../../components/LoadingImageRotate'
import MenuGeneral from '../../components/MenuGeneral'


const PerformancePage = () => {
    const { token } = useAuth()

    const [contentPerformancePlayer, setContentPerformancePlayer] = useState(null)
    const [detalhesAgrupados, setDetalhesAgrupados] = useState(null)
    const [loadingPage, setLoadingPage] = useState(true)
    const [expandedDetail, setExpandedDetail] = useState(null)

    const navigate = useNavigate()

    //configuração de permanencia de login
    if (!token) {
        return <Navigate to="/" />
    }

    useEffect(() => {
        console.log('contentPerformancePlayer foi alterado:', contentPerformancePlayer)
    }, [contentPerformancePlayer])

    useEffect(() => {
        console.log('detalhesAgrupados foi alterado:', detalhesAgrupados)
    }, [detalhesAgrupados])
    
    
    // Função que agrupa de acordo com o ID do acordo
    function agruparPorIdAcordo(detalhes) {
        console.log('Iniciando agruparPorIdAcordo')
        const groupedDetails = {}
    
        detalhes.forEach(detail => {
        const { id_acordo, nome_cliente, ...rest } = detail
        if (!groupedDetails[id_acordo]) {
            groupedDetails[id_acordo] = []
        }
        groupedDetails[id_acordo].push({ nome_cliente, ...rest })
        })

        console.log('Após o forEach groupedDetails', groupedDetails)
    
        const result = Object.keys(groupedDetails).map(id_acordo => {
            console.log('iniciando result, gerando nova estrutura...')
            if (groupedDetails[id_acordo].length > 1) {
                return {
                id_acordo: parseInt(id_acordo),
                nome_cliente: groupedDetails[id_acordo][0].nome_cliente,
                detail_idacordo_group: groupedDetails[id_acordo]
                }
            } else {
                return {
                id_acordo: parseInt(id_acordo),
                ...groupedDetails[id_acordo][0]
                }
            }
        }
        )
        console.log('resultado de result de  nova estrutura', result)
    
        return result
    }
    
    const toggleDetail = (id_acordo) => {
        if (expandedDetail === id_acordo) {
          setExpandedDetail(null)
        } else {
          setExpandedDetail(id_acordo)
        }
      }

    function formatarReal(valor) {
        // Converte o valor para string
        const valorString = valor.toString()
      
        // Separa a parte inteira da parte decimal
        const parteInteira = valorString.split(".")[0]
        let parteDecimal = valorString.split(".")[1] || "00"
      
        if (parteDecimal.length < 2) {
          parteDecimal += "0"
        }
      
        // Formata a parte inteira com máscara de milhares
        const parteInteiraFormatada = parteInteira.replace(/(\d)(?=(\d{3})+$)/g, "$1.")
      
        // Retorna o valor formatado
        return `R$ ${parteInteiraFormatada},${parteDecimal}`
      }
    
      function formatarDataBR(data) {
        // Converte a data para um objeto Date
        const dataObj = new Date(data)
      
        // Retorna a data formatada no padrão dd/mm/aaaa
        return `${dataObj.getDate()}/${dataObj.getMonth() + 1}/${dataObj.getFullYear()}`
      }

      useEffect(() => {
        const fetchData = async () => {
          console.log('Iniciando useEffect')
          try {
            const response = await axios.get(`${process.env.REACT_APP_REQ}user/performance/`, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
            const returnReq = response.data
            console.log('console response.data', response.data)
            setContentPerformancePlayer(returnReq)
            const dadosAgrupados = response.data.detalhes
            console.log('dados agrupados:', dadosAgrupados)
            const dadosAgrupadosSetVariavel = agruparPorIdAcordo(dadosAgrupados)
            console.log('dados após função:', dadosAgrupadosSetVariavel)
            setDetalhesAgrupados(dadosAgrupadosSetVariavel)
          } catch (error) {
            console.error('Ocorreu um erro ao buscar os dados:', error)
            if (error.response && error.response.status === 401) {
            navigate('/logout')
          }
          } finally {
            setLoadingPage(false)
          }
        }
    
        fetchData()
      }, [])
    
      const handleReloadPage = () => {
        <Navigate to="/search" />
    }

return(
    <>
        <div className='performancePlayerPageRow'>
            <MenuGeneral textColor="#000000" backgroundColor="#6051b7" />
            <div className="performancePlayerPage">
                <div className='performancePlayerPageFontSize40'><b>NEGOCIAÇÕES REALIZADAS</b></div>
                {loadingPage && <LoadingImageRotate loadingImage={loadingImageFromPage} altLoadingImage={'Icone de loading'} width={'80px'} text={'Carregando negociações...'} />}
                {contentPerformancePlayer && detalhesAgrupados &&
                    <>
                        <div className="performancePlayerPageUser">
                            <img src={userImg} alt={'User icon'} className={'performancePlayerPageUserIcon'}></img>
                            <div className="performancePlayerPageUserInfo"><b>{contentPerformancePlayer.agente.toUpperCase()}</b></div>
                        </div>
                        <div className="performancePlayerPagePerformanceColumn">
                            <div className='performancePlayerPagePerformanceColumnItem'>
                            <div className='performancePlayerPagePerformanceColumnItemLabel performancePlayerPageColorPurple'><b>Total negociado</b></div>
                            <div className='performancePlayerPageColorPurple performancePlayerPageFontSize40'><b>{formatarReal(contentPerformancePlayer.total_negociado)}</b></div>
                            </div>
                            <div className='performancePlayerPagePerformanceColumnItem'>
                            <div className='performancePlayerPagePerformanceColumnItemLabel performancePlayerPageColorGreen'><b>Total pago</b></div>
                            <div className='performancePlayerPageColorGreen performancePlayerPageFontSize40'><img src={checkedImg} alt={'icon checked'} className={'performancePlayerPageUserIconChecked'} /> <b>{formatarReal(contentPerformancePlayer.total_pago)}</b></div>
                            </div>
                        </div>
                        <div className='performancePlayerPageColorOrange performancePlayerPageFontSize24'><b>A C O R D O S</b></div>
                        <br/>
                        {detalhesAgrupados && detalhesAgrupados.map(detail => (
                            <div key={detail.id_acordo} className='performancePlayerPageIdAcordo'>
                            <div onClick={() => toggleDetail(detail.id_acordo)} className='performancePlayerPageContentChoose'>
                                <img src={listImg} alt={'Icon Lista'} className={'performancePlayerPageUserIcon'} />
                                <div className='performancePlayerPageFontSize20'><b>ACORDO: {detail.id_acordo} <spam className='performancePlayerPageColorPurple'>{detail.nome_cliente.toUpperCase()}</spam></b></div>
                            </div>
                            {expandedDetail === detail.id_acordo && ( 
                                <div className='performancePlayerPageContentSelect'>
                                {detail.detail_idacordo_group ? (
                                    detail.detail_idacordo_group.map(groupDetail => (
                                    <div key={groupDetail.id_acordo} className='performancePlayerPageItemContent'>
                                        <p>Nome do cliente: <b>{groupDetail.nome_cliente.toUpperCase()}</b></p>
                                        <p>E-mail do cliente: <b>{groupDetail.email_cliente}</b></p>
                                        <p>Acordo externo(nº): <b>{groupDetail.acordo_externo}</b></p>
                                        <p>Número da fatura: <b>{groupDetail.numero_fatura}</b></p>
                                        <p>Vencimento da fatura: <b>{formatarDataBR(groupDetail.vencimento_fatura)}</b></p>
                                        <p>Valor da fatura: <b>{formatarReal(groupDetail.valor_fatura)}</b></p>
                                        <p>Fatura está paga: <b>{groupDetail.fatura_paga ? 'Sim' : 'Não'}</b></p>
                                        {groupDetail.link_fatura && (
                                        <p>Link da fatura: <a href={groupDetail.link_fatura} target="_blank"  rel="noreferrer">Acesse aqui!</a></p>
                                        )}
                                    </div>
                                    ))
                                ) : (
                                    <> 
                                    <div  className='performancePlayerPageItemContent'>
                                    <p>Nome do cliente: <b>{detail.nome_cliente.toUpperCase()}</b></p>
                                    <p>E-mail do cliente: <b>{detail.email_cliente}</b></p>
                                    <p>Acordo externo(nº): <b>{detail.acordo_externo}</b></p>
                                    <p>Número da fatura: <b>{detail.numero_fatura}</b></p>
                                    <p>Vencimento da fatura: <b>{formatarDataBR(detail.vencimento_fatura)}</b></p>
                                    <p>Valor da fatura: <b>{formatarReal(detail.valor_fatura)}</b></p>
                                    <p>Fatura está paga: <b>{detail.fatura_paga ? 'Sim' : 'Não'}</b></p>
                                    {detail.link_fatura && (
                                        <p>Link da fatura: <a href={detail.link_fatura} target="_blank"  rel="noreferrer">Acesse aqui!</a></p>
                                    )}
        
                                    </div>
                                    </>
                                )}
                                </div>
                            )}
                            </div>
                        ))}
        
                    </>
                }


            </div>
        </div>
    </>
)
}

export default PerformancePage